import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrl: './external-login.component.scss'
})
export class ExternalLoginComponent implements OnInit{
  form!:FormGroup;

  constructor(
    private adminService: AdminService, 
    private formBuilder: FormBuilder,
  ) {}
  ngOnInit(){
    this.form = this.formBuilder.group({
      url:["", Validators.required],
      username:["", Validators.required],
      password:["", Validators.required],
     })
  }

  login() {
    this.adminService.loginToExternalPortal(this.form.value.url, this.form.value.username, this.form.value.password)
    .pipe(first())
    .subscribe({
      next: (data: any) => {
        console.log("Successful");
      }
    }),
    (error: any) => {
      console.log(error);

    };
  }
}
